import React, { useState } from "react";
import { signUp } from "aws-amplify/auth";
import "./component_css/Registration.css";
import { put, post, ApiError } from "@aws-amplify/api";

const RegistrationForm = ({ buttonLabel = "Register Team", modalTitle = "Register Team" }) => {
  const initialFormData = {
    coachEmail: "",
    coachName: "",
    teamName: "",
    isCoach: false,
    numStudents: "",
    studentEmails: [],
    studentNames: [],
    academicLevel: "",
    competitionLevel: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const [selectedButton, setSelectedButton] = useState(null); // Track the selected button

  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = () => {
    setError("");
    setIsModalOpen(false); // Close the modal
    setFormData(initialFormData); // Reset form
    setSelectedButton(null);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleNumStudentsChange = (num) => {
    setFormData({
      ...formData,
      numStudents: num,
      studentEmails: Array(num).fill(""), // Reset student emails based on number selected
    });
    setSelectedButton(num);
  };

  const handleStudentEmailChange = (index, value) => {
    const updatedEmails = [...formData.studentEmails];
    updatedEmails[index] = value;
    setFormData({
      ...formData,
      studentEmails: updatedEmails,
    });
  };

  const handleStudentNameChange = (index, value) => {
    const updatedNames = [...formData.studentNames];
    updatedNames[index] = value;
    setFormData({
      ...formData,
      studentNames: updatedNames,
    });
    console.log(formData)
  };

  // Handle selection of academic level (High School or Middle School)
  const handleAcademicLevelChange = (level) => {
    setFormData({
      ...formData,
      academicLevel: level,
    });
  };

  // Handle selection of competition level (Basic or Advanced)
  const handleCompetitionLevelChange = (level) => {
    setFormData({
      ...formData,
      competitionLevel: level,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create the JSON payload in the correct format
    const formTemplate = {
      AWSRegion: "us-east-1", // Region field
      TeamName: formData.teamName,
      HowManyStudentsAreInYourTeam: formData.numStudents,
      AcademicLevel: formData.academicLevel, 
      CompetitionLevel: formData.competitionLevel,
      AreYouRegisteringWithACoach: formData.isCoach, 
      Coach: {
        Email: formData.isCoach ? formData.coachEmail : "",
        Name: {
          FirstAndLast: formData.isCoach ? formData.coachName : ""
        }
      },

      ...Array.from({ length: formData.numStudents }, (_, index) => {
        const playerIndex = index + 1; // Player index starts from 1
        return {
          [`Player${playerIndex}`]: {
            Email: formData.studentEmails[index] || "",
            Name: {
              FirstAndLast: formData.studentNames[index] || "",
            },
          },
        };
      }).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
    };
    
    try {
      const restOperation = await put({
        apiName: "register",
        path: '/',
        options: {
          body: formTemplate
        }
      })

      const response = await restOperation.response;
  
      alert("Sign-up successful for all students!");
      handleModalClose(); // Close modal on success
    } catch (err) {
      if (err instanceof ApiError) {
        if (err.response) {
          const { statusCode, headers, body } = err.response;
          console.error(`Received ${statusCode} error response with payload: ${body}`);
    
          setError(body);
        } else {
          console.log("ERROR: ", err);
          setError("Error occurred. Please contact tech support" );
        }
      } else {
        console.log("Unknown error: ", err);
        setError("An unknown error occurred. Please contact tech support" );
      }
    }
  };
  

  return (
    <>
      <button onClick={() => setIsModalOpen(true)}>{buttonLabel}</button>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleModalClose}>
              &times;
            </span>
            <h2>{modalTitle}</h2>
            <form onSubmit={handleSubmit}>
              <div className="section">
                <div className="checkbox-container">
                  <label>Register with Coach?</label>
                  <input
                    type="checkbox"
                    name="isCoach"
                    checked={formData.isCoach}
                    onChange={handleInputChange}
                  />
                </div>

                {formData.isCoach && (
                  <div>
                    <label>Coach Email:</label>
                    <input
                      type="email"
                      name="coachEmail"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                    <label>Coach Name (First and Last):</label>
                    <input
                      type="text"
                      name="coachName"
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                )}
              </div>

              <div className="section">
                <label>Team Name:</label>
                <input
                  type="text"
                  name="teamName"
                  value={formData.teamName}
                  onChange={handleInputChange}
                  required
                />

                <label>Academic Level:</label>
                <div className="level-buttons">
                  {["High School", "Middle School"].map((level) => (
                    <button
                      key={level}
                      type="button"
                      className={`level-button ${formData.academicLevel === level ? "selected" : ""}`}
                      onClick={() => handleAcademicLevelChange(level)}
                    >
                      {level}
                    </button>
                  ))}
                </div>

                <label>Competition Level:</label>
                <div className="level-buttons">
                  {["Beginner", "Advanced"].map((level) => (
                    <button
                      key={level}
                      type="button"
                      className={`level-button ${formData.competitionLevel === level ? "selected" : ""}`}
                      onClick={() => handleCompetitionLevelChange(level)}
                    >
                      {level}
                    </button>
                  ))}
                </div>

                {/* Number of Students Buttons */}
                <label>Number of Students:</label>
                <div className="student-buttons">
                  {[1, 2, 3, 4, 5, 6].map((num) => (
                    <button
                      key={num}
                      type="button"
                      className={`student-button ${selectedButton === num ? "selected" : ""}`}
                      onClick={() => handleNumStudentsChange(num)}
                    >
                      {num}
                    </button>
                  ))}
                </div>
              </div>
              
              <div className="section">
                {formData.numStudents > 0 && (
                  <div>
                    <h3>Student Emails:</h3>
                    {formData.studentEmails.map((email, index) => (
                      <div key={index}>
                        <label>Student {index + 1} Email:</label>
                        <input
                          type="email"
                          value={email}
                          onChange={(e) => handleStudentEmailChange(index, e.target.value)}
                          required
                        />
                        <label>Student {index + 1} Name (First and Last):</label>
                        <input
                          type="text"
                          name="studentNames"
                          onChange={(e) => handleStudentNameChange(index, e.target.value)}
                          required
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {error && <p className="error">{error}</p>}
              <button type="submit">Register</button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default RegistrationForm;
